import {
  format,
  parse
} from 'date-fns';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Label from 'web-react-ui/src/components/elements/Label';
import List from 'web-react-ui/src/components/elements/List';
import Button from 'web-react-ui/src/components/elements/Button';
import imageParams from 'web-react-ui/src/utils/imageParams';
import BigModal from 'web-react-ui/src/BigModal/BigModal';
import OfferPreview from 'web-react-ui/src/components/preview/OfferPreview';

const getDateRangeStr = (offer) => {
  const firstSchedule = offer.discoverableSchedules && offer.discoverableSchedules?.[0];
  if (!firstSchedule) return 'No Schedule';

  const startDate = firstSchedule.startDate;
  const endDate = firstSchedule.endDate;

  const startDateStr = format(parse(startDate, 'yyyy-MM-dd', new Date()), 'MMM d');
  if (!endDate) return `Live ${startDateStr}`;

  const endDateStr = format(parse(endDate, 'yyyy-MM-dd', new Date()), 'MMM d');
  return `${startDateStr} - ${endDateStr}`;
};

class OfferListItem extends Component {
  static propTypes = {
    propertyId: PropTypes.string,
    locationId: PropTypes.string,
    offer: PropTypes.object,
    relativeLink: PropTypes.func,
    path: PropTypes.string,
    showBackground: PropTypes.bool,
    approveOffer: PropTypes.func,
    declineOffer: PropTypes.func
  };

  state = {
    openModal: false
  };

  openPreviewModal() {
    this.setState({ openModal: true });
  }

  handleCancel() {
    this.setState({ openModal: false });
  }

  render() {
    const {
      propertyId, offer, relativeLink, path, showBackground, approveOffer, declineOffer
    } = this.props;

    if (!offer) return null;
    if (!propertyId) return null;
    if (!offer.businessId) return null;

    const flags = offer.flags;
    const isLimited = flags && flags.includes('limited');
    const isExclusive = flags && offer.flags.includes('exclusive') && !isLimited;

    const offerSchedule = getDateRangeStr(offer);

    const imgixUrl = imageParams(offer.heroImageUrl, { w: '80px', h: '80px', fit: 'crop' });

    return (
      <List.Item as={relativeLink} to={path}>
        <div className="flex aic fww">
          <div
            className="flex fg1 fb0 mt1 mb1"
            style={{ minWidth: '300px' }}
            onClick={this.openPreviewModal.bind(this)}
          >
            <div
              className="mr1"
              style={{
                backgroundColor: (imgixUrl && !showBackground) ? 'transparent' : '#999999',
                width: '80px',
                minWidth: '80px',
                height: '80px',
                display: 'flex',
                alignSelf: 'start',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundImage: imgixUrl && `url(${imgixUrl})`,
                backgroundSize: showBackground ? '70%' : 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat'
              }}
              title={offer.headline}
            />
            <List.Content>
              <div className="flex">
                <List.Header>{offer.headline}</List.Header>
              </div>
              <List.Description>{offer.subHeadline}</List.Description>
              <List.Content className="mla">
                <div className="flex">
                  {isLimited && (
                    <div className="mt1">
                      <Label size="mini" color="orange">LIMITED QTY</Label>
                    </div>
                  )}
                  {isExclusive && (
                    <div className="mt1">
                      <Label size="mini" color="purple">EXCLUSIVE</Label>
                    </div>
                  )}
                </div>
                <div className="mt1">
                  <List.Description>{offerSchedule}</List.Description>
                </div>
              </List.Content>
            </List.Content>
          </div>
          <div className="flex aic fg1 fb0 mt1 mb1" style={{ minWidth: '300px' }}>
            <List.Content className="mla">
              <div>
                <List.Header>{offer.business.name}</List.Header>
                {offer.locations.length > 1 && (
                  <List.Description>{`${offer.locations.length} locations`}</List.Description>
                )}
                {offer.locations.length === 1 && (
                  <List.Description>{offer.locations[0] && offer.locations[0].name}</List.Description>
                )}
              </div>
            </List.Content>
            <List.Content className="mla">
              <Button primary onClick={() => approveOffer(offer.id)}>Approve</Button>
              <Button primary onClick={() => declineOffer(offer.id)} basic>Dismiss</Button>
            </List.Content>
          </div>
        </div>
        <BigModal open={this.state.openModal}>
          <BigModal.Contents>
            <BigModal.Header className="p1">
              <BigModal.CloseButton className="p1" floated="right" onClick={this.handleCancel.bind(this)} />
            </BigModal.Header>
            <BigModal.Body>
              <div className="flex">
                <div className="mla mra" style={{ width: '400px', height: '500px' }}>
                  <OfferPreview isSyndicated offer={offer} image={offer.heroImage} />
                </div>
              </div>
              <div className="m4 pull-right">
                <Button primary onClick={() => approveOffer(offer.id)}>Approve</Button>
              </div>
            </BigModal.Body>
          </BigModal.Contents>
        </BigModal>
      </List.Item>
    );
  }
}

export default OfferListItem;
